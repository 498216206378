.p-8 {
  padding: 8px !important;
}

.p-16 {
  padding: 16px !important;
}

.p-24 {
  padding: 24px !important;
}

.p-32 {
  padding: 32px !important;
}

.m-8 {
  margin: 8px !important;
}

.m-16 {
  margin: 16px !important;
}

.m-24 {
  margin: 24px !important;
}

.m-32 {
  margin: 32px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.relative {
  position: relative;
}

.align-items-center {
  align-items: center;
}

.mad-header-section--sticky-xl{
  display: none !important;
}