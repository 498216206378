.py144 {
  padding: 90px 0 80px;
  font-size: 22px;
  line-height: 1.75;
}

.imgObjectFit {
  object-fit: contain;
  width: 100%;
}

.menuBtn {
  border: 1px solid;
  color: #ffffff;
  cursor: pointer;
  background-color: #ddaa59;
  border-color: #ddaa59;
  padding: 18px 44px;
  transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
    background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
    border-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.menuBtn:hover {
  background: transparent;
  color: #ddaa59;
}

.bgImage {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url("/public//bankok/bg-img.webp");
  transform-origin: 50% 50%;
  transform: perspective(900px) translate3d(0px, 0px, 0px);
}

.bgImage2 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url("https://bangkokfactory.fr/wp-content/uploads/2021/11/Bangkok-Factory-Restaurant-intérieur-min.jpg");
  transform-origin: 50% 50%;
  transform: perspective(900px) translate3d(0px, 0px, 0px);
}

.cardWrp {
  text-align: center;
  padding: 108px 0;
  background-color: transparent;
}

.overlay {
  border-radius: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  transition: opacity 0.2s;
  opacity: 0.8;
  background-color: #141618;
  z-index: -1;
}

.cardTitle {
  font-size: 20px;
  margin: 20px 0;
}

.cardDescription {
  line-height: 1.75;
  margin: 18px 0px 0px 0px;
}

.verticalText {
  writing-mode: vertical-rl;
  position: absolute !important;
  margin: 0 !important;
  top: 0;
  bottom: 0;
  text-align: center;
  transform: rotate(180deg) translateX(50%);
  color: #edd83d;
  font-size: 18px;
}

.verticalText2 {
  writing-mode: vertical-rl;
  position: absolute !important;
  margin: 0 !important;
  top: 0;
  bottom: 0;
  right: 20px;
  text-align: center;
  transform: rotate(0deg) translateX(50%);
  color: #edd83d;
  font-size: 18px;
}

.videoContainer {
  position: relative;
  height: 90vh;
  width: 100%;
  overflow: hidden;
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  text-align: center;
  animation: transdorm 0.5s ease-in-out;
}

.hideText {
  transform: translateY(-100%);
}

.content h1 {
  font-size: 4rem;
  margin: 0;
}

.firstImage {
  z-index: 0;
  position: absolute;
  top: 10%;
  width: 50%;
  right: 25%;
  animation: bounce 8s ease-in-out infinite;
}

.bagImg {
  position: absolute;
  z-index: 2;
  left: 15%;
  top: 20%;
  width: 60%;
  animation: bounce 4s ease-out infinite;
}

.secondImage {
  position: absolute;
  z-index: 1;
  left: 0;
  width: 50%;
  transform: translateX(20%);
  /* animation: bounce 6s ease infinite; */
}

.image2 {
  position: absolute;
  z-index: 2;
  width: 50%;
  top: 25%;
  left: 20%;
  animation: bounce 6s ease-in-out infinite;
}

.image1 {
  position: absolute;
  width: 50%;
  aspect-ratio: 1 / 1;
  top: 5%;
  animation: bounce 4s ease-out infinite;
}

.image3 {
  top: 20%;
  z-index: 0;
  width: 50%;
  position: absolute;
  right: 10%;
  aspect-ratio: 1 / 1;
  animation: bounce 8s ease-in-out infinite;
}

.leftOutViewAnimation {
  transform: translateX(-100%);
  transition: transform 1s ease;
}

.rightOutViewAnimation {
  transform: translateX(100%);
  transition: transform 1s ease;
}

.inViewAnimation {
  transition: transform 1s ease;
  transform: translate(0);
  padding-top: 30px;
}

.spinner {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 991px) {
  .py144 {
    padding: 20px 0;
  }

  .cardWrp {
    padding: 48px 0;
  }

  .content h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 769px) {
  .content h1 {
    font-size: 2rem;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  20%,
  80% {
    transform: translateY(8px); /* Adjust this value for bounce height */
  }
  50% {
    transform: translateY(-5px); /* Adjust this value for bounce height */
  }
}
