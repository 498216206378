.menu-btn-style {
  border: 1px solid;
  color: #ffffff;
  cursor: pointer;
  background-color: #ddaa59;
  border-color: #ddaa59;
  border-radius: 30px;
  text-transform: uppercase;
  font-size: 18px;
  /* margin-left: 16px; */
  padding: 18px 30px;
  transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
    background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
    border-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.menu-btn-style:hover {
  background: transparent;
  color: #ddaa59;
}

section.css-4kplgf,
.css-95a6y4-ModalBody,
.css-dk2yag-ModalHeader,
.css-1dmps4x-ModalFooter {
  background-color: #1b1d1f !important;
  color: #fff !important;
}

/* .modal-background {
  background-color: #1b1d1f !important;
}

.modal-custom-background {
  background-color: #1b1d1f !important;
} */

.menu-title-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
