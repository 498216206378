.menu-btn-style {
  border: 1px solid;
  color: #ffffff;
  cursor: pointer;
  background-color: #ddaa59;
  border-color: #ddaa59;
  /* text-transform: uppercase; */
  /* font-size: 18px; */
  border-radius: 30px;
  text-transform: capitalize;
  /* margin-left: 16px; */
  /* padding: 18px 44px; */
  transition: color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
    background-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86),
    border-color 200ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.menu-btn-style:hover {
  background: transparent;
  color: #ddaa59;
}
